import React, { useState, useEffect, useRef, useMemo } from "react";

import { SLIDES } from "../../../../content/hero";

import IconLongArrow from "../../../assets/icons/long-arrow.svg";
import IconArrow from "../../../assets/icons/arrow.svg";

import { useTranslations } from "../../../hooks/translations";

import Swiper from "../../swiper";

import Slide from "./slide";

import styles from "./hero.module.scss";

const Hero = () => {
  const t = useTranslations("hero");

  const [slideIndex, setSlideIndex] = useState(1);

  const swiperContainerRef = useRef();
  const swiperPrevSlideRef = useRef();
  const swiperNextSlideRef = useRef();

  useEffect(() => {
    const swiper = new Swiper(swiperContainerRef.current, {
      preloadImages: false,
      loop: true,
      lazy: {
        loadPrevNext: true
      },
      navigation: {
        nextEl: swiperNextSlideRef.current,
        prevEl: swiperPrevSlideRef.current
      },
      on: {
        slideChange: () => setSlideIndex(swiper.realIndex + 1)
      }
    });
  }, []);

  const visibleSlides = useMemo(() => SLIDES.filter(({ hidden }) => !hidden), []);

  return (
    <div
      className={`swiper-container bp-section-alternative ${styles.container}`}
      ref={swiperContainerRef}
    >
      <div className={`bp-container ${styles.pages}`}>
        <button
          type="button"
          aria-label={t.previousSlide}
          className={`${styles.changeSlide} ${styles.prev}`}
          ref={swiperPrevSlideRef}
        >
          <IconArrow className="bp-stroke" />
        </button>
        <span>
          <span className={styles.slideIndex}>{slideIndex}</span>/
          <span className={styles.slideIndex}>{visibleSlides.length}</span>
        </span>
        <button
          type="button"
          aria-label={t.nextSlide}
          className={`${styles.changeSlide} ${styles.next}`}
          ref={swiperNextSlideRef}
        >
          <IconArrow className="bp-stroke" />
        </button>
      </div>

      <div className={`swiper-wrapper ${styles.slidesWrapper}`}>
        {visibleSlides.map(({ key, url, email, img }) => (
          <Slide
            key={key}
            heading={t[key].heading}
            description={t[key].description}
            cta={t[key].cta}
            contactModalHeading={t[key].contactModalHeading}
            actionURL={url}
            email={email}
            image={img}
          />
        ))}
      </div>

      <IconLongArrow className={`bp-stroke ${styles.longArrow}`} />
    </div>
  );
};

export default Hero;
