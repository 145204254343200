import React, { useState } from "react";
import PropTypes from "prop-types";

import ButtonLink from "../../button-link";
import Button from "../../button";
import ContactModal from "../../contact-modal";

import styles from "./slide.module.scss";

const Slide = ({ heading, description, cta, contactModalHeading, actionURL, email, image }) => {
  const [contactModalVisible, setContactModalVisible] = useState(false);

  return (
    <div
      className={`swiper-slide swiper-lazy bp-section-alternative bp-background ${styles.container}`}
      data-background={image}
    >
      <div className={`bp-full-screen ${styles.opaque}`} />
      <div className="swiper-lazy-preloader" />
      <div className={`bp-container ${styles.main}`}>
        <h1 className={styles.heading}>{heading}</h1>
        <p className={styles.subheading}>{description}</p>
        {actionURL ? (
          <ButtonLink className="bp-standard" to={actionURL}>
            {cta}
          </ButtonLink>
        ) : (
          <Button
            className="bp-standard"
            onClick={() => setContactModalVisible(email)}
            aria-haspopup="dialog"
            aria-expanded={!!contactModalVisible}
          >
            {cta}
          </Button>
        )}
      </div>

      {contactModalVisible ? (
        <ContactModal
          onClose={() => setContactModalVisible(false)}
          message={contactModalHeading}
          email={contactModalVisible}
        />
      ) : null}
    </div>
  );
};

Slide.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  contactModalHeading: PropTypes.string,
  actionURL: PropTypes.string,
  email: PropTypes.string,
  image: PropTypes.string.isRequired
};

Slide.defaultProps = {
  contactModalHeading: null,
  actionURL: null,
  email: null
};

export default Slide;
