import VolunteerImg from "../src/assets/photos/hero-volunteer.jpg";
import PartnerImg from "../src/assets/photos/hero-partner.jpg";
import DonateImg from "../src/assets/photos/hero-donate.jpg";
import ReportImg from "../src/assets/photos/hero-report.jpg";

import CONTACTS from "./contact.json";
import { isElectionOver } from "../src/utils/calendar";

export const SLIDES = [
  {
    key: "volunteer",
    url: "/register",
    img: VolunteerImg,
    hidden: isElectionOver()
  },
  {
    key: "partner",
    email: CONTACTS.partnersEmail,
    img: PartnerImg
  },
  {
    key: "donate",
    url: "/donate",
    img: DonateImg
  },
  {
    key: "report",
    url: "/report-violation",
    img: ReportImg,
    hidden: isElectionOver()
  }
];
